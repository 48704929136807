<template>
  <v-data-table
    :headers="readableAndActionHeaders"
    :items="items"
    :loading="loading"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      'items-per-page-options': itemsPerPageOptions,
      'items-per-page-text': 'Số lượng / trang'
    }"
  >
    <!-- Header, Create, Delete -->
    <template v-slot:top>
      <!-- Title -->
      <v-toolbar flat>
        <div>
          <v-toolbar-title
            >{{ title || "Danh sách " + itemName }}
          </v-toolbar-title>
          <div class="caption">{{ subtitle }}</div>
        </div>

        <v-divider class="mx-4" inset vertical></v-divider>

        <v-text-field
          v-model="query"
          hide-details
          placeholder="Tìm kiếm"
          prepend-inner-icon="mdi-magnify"
          clearable
        />

        <v-spacer />

        <!-- Slot -->
        <slot name="actions" />

        <!-- Create/Edit Dialog -->
        <v-dialog v-model="dialog" max-width="500px" scrollable persistent>
          <template v-if="creatable" v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Tạo mới {{ itemName }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="font-weight-bold h5"
                >{{ isCreateNew ? "Tạo mới" : "Sửa" }}
                {{ itemName.toLowerCase() }}</span
              >
            </v-card-title>

            <v-card-text style="max-height: 80vh">
              <v-form ref="form" v-model="formValid">
                <template v-for="header of editableHeaders">
                  <!-- DateTime -->
                  <DatePicker
                    v-if="header.dataType === 'datetime'"
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                  />

                  <!-- Number -->
                  <v-text-field
                    v-else-if="header.dataType === 'number'"
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :key="header.value"
                  />

                  <!-- Boolean -->
                  <v-checkbox
                    v-else-if="header.dataType === 'boolean'"
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                  />

                  <!-- Choice -->
                  <v-select
                    v-else-if="header.dataType === 'choice'"
                    v-model="editedItem[header.value]"
                    :items="header.data"
                    :label="header.text"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                    item-text="text"
                    item-value="value"
                  />

                  <!-- Multiple Choice -->
                  <v-select
                    v-else-if="header.dataType === 'multiple-choice'"
                    v-model="editedItem[header.value]"
                    :items="header.data"
                    :label="header.text"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                    multiple
                    item-text="text"
                    item-value="value"
                  />

                  <!-- Belongs To -->
                  <ServerAutocomplete
                    v-else-if="header.dataType === 'belongs-to'"
                    v-model="editedItem[header.dataValue]"
                    :label="header.text"
                    :item-text="header.dataText"
                    :url="header.dataUrl"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                    return-object
                    item-value="id"
                  />

                  <!-- Has Many -->
                  <ServerAutocomplete
                    v-else-if="header.dataType === 'has-many'"
                    v-model="editedItem[header.dataValue]"
                    :label="header.text"
                    :item-text="header.dataText"
                    :url="header.dataUrl"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                    multiple
                    return-object
                    item-value="id"
                  />

                  <!-- Text -->
                  <v-text-field
                    v-else
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :key="header.value"
                    :rules="
                      (header.rules &&
                        header.rules
                          .split('|')
                          .map(r => rules[r](header.dataType))) ||
                        []
                    "
                  />
                </template>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close"> Hủy</v-btn>
              <v-btn color="primary" :loading="editLoading" @click="save">
                {{ isCreateNew ? "Tạo mới" : "Cập nhật" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Delete Dialog -->
        <v-dialog v-if="removable" v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="h5 font-weight-bold">
              Xóa {{ itemName }}
            </v-card-title>

            <v-card-text>
              Bạn có chắc chắn muốn xóa {{ itemName }}?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete"> Hủy</v-btn>
              <v-btn
                color="error"
                :loading="deleteLoading"
                @click="deleteItemConfirm"
              >
                Xóa
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <!-- Content -->
    <template
      v-for="header of readableHeaders"
      v-slot:[`item.${header.value}`]="{ item }"
    >
      <slot :name="`item.${header.value}`" :item="item">
        <!-- DateTime -->
        <div v-if="header.dataType === 'datetime'" :key="header.value">
          {{ item[header.value] | dateTime }}
        </div>

        <!-- Boolean -->
        <v-simple-checkbox
          v-else-if="header.dataType === 'boolean'"
          :key="header.value"
          :value="item[header.value]"
          :ripple="false"
          color="primary"
          style="cursor: default !important"
        />

        <!-- Belongs To -->
        <div v-else-if="header.dataType === 'belongs-to'" :key="header.value">
          {{ item[header.dataValue][header.dataText] }}
        </div>

        <!-- Has Many -->
        <div v-else-if="header.dataType === 'has-many'" :key="header.value">
          <div
            v-for="(val, i) of item[header.dataValue].map(
              i => i[header.dataText]
            )"
            :key="i"
          >
            {{ val }}
          </div>
        </div>

        <!-- Choice -->
        <div v-else-if="header.dataType === 'choice'" :key="header.value">
          {{
            (header.data.find(i => i.value === item[header.value]) &&
              header.data.find(i => i.value === item[header.value]).text) ||
              item[header.value]
          }}
        </div>

        <!-- Multi Choice -->
        <div
          v-else-if="header.dataType === 'multiple-choice'"
          :key="header.value"
        >
          <div
            v-for="(val, i) of item[header.dataValue].map(
              i => i[header.dataText]
            )"
            :key="i"
          >
            {{ val }}
          </div>
        </div>

        <!-- Text (default) -->
        <div v-else :key="header.value">
          {{ item[header.value] }}
        </div>
      </slot>
    </template>

    <!-- Actions -->
    <template v-slot:[`item.actions`]="{ item }">
      <slot name="item-actions" :item="item" />

      <v-btn v-if="editable" title="Edit" icon @click="editItem(item)">
        <v-icon> mdi-pencil</v-icon>
      </v-btn>

      <v-btn v-if="removable" icon title="Delete" @click="deleteItem(item)">
        <v-icon> mdi-delete</v-icon>
      </v-btn>
    </template>

    <!-- No Data -->
    <template v-slot:no-data>
      <v-btn color="primary" :loading="loading" @click="fetchData">
        Refresh
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "./debounce";
import ServerAutocomplete from "./ServerAutocomplete.vue";
import request from "@/utils/request";
import DatePicker from "./DatePicker.vue";
import dayjs from "dayjs";

/**
 * Header Item Data Types (ItemDataType)
 * - belongs-to
 *    - value: foreign key field. E.g. "dataset_id", "datasource_id"
 *    - dataText: display field. E.g. "name", "description"
 *    - dataValue: relationship name. E.g. "dataset", "datasource"
 *    - dataUrl: url to communicate with server. E.g. "/datasets/", "/datasources/"
 *
 * - has-many
 *    - value: foreign key field. E.g. "dataset_id", "datasource_id"
 *    - dataText: display field. E.g. "name", "description"
 *    - dataValue: relationship name. E.g. "datasets", "datasources"
 *    - dataUrl: url to interact with server. E.g. "/datasets/", "/datasources/"
 *
 * - choice
 *    - data: { text: string, value: any }[]
 *
 * - multiple-choice
 *    - data: { text: string, value: any }[]
 *
 * - datetime
 *
 * - number
 *
 * - boolean
 *
 * - text (default)
 *
 * Item Properties
 * - dataType: ItemDataType // See above
 * - readOnly: boolean // This field is read only. It'll not appear in create & update forms
 * - editOnly: boolean // This field is edit only. It'll appear in create & update forms but not in table list
 * - rules: string // List of rules, separate by | character. Supports: required, length, email
 *
 * Slot
 * - actions: Header actions (E.g. Create)
 * - item-actions: Item actions (E.g. Update, Delete)
 * - item.${header.value}: Item display customization
 *
 */
export default {
  props: {
    // Model
    title: {
      type: String,
      default: undefined
    },

    subtitle: {
      type: String,
      default: undefined
    },

    itemName: {
      type: String,
      required: true
    },

    url: {
      type: String,
      default: undefined
    },

    // Header
    headers: {
      type: Array, // { text: string, value: string, type: string }[] // See more options:
      default: () => []
    },

    creatable: {
      type: Boolean,
      default: false
    },

    editable: {
      type: Boolean,
      default: false
    },

    removable: {
      type: Boolean,
      default: false
    },

    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 20, 50]
    }
  },

  filters: {
    dateTime(val) {
      if (!val) return;

      return dayjs(val).format("DD/MM/YYYY hh:mm A");
    }
  },

  components: {
    ServerAutocomplete,
    DatePicker
  },

  data: () => ({
    dialog: false,
    loading: false,
    dialogDelete: false,

    query: "",

    options: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10
    },
    totalItems: 0,

    items: [],

    editedIndex: -1,
    editedItem: {},

    deleteLoading: false,

    editLoading: false,

    formValid: false,
    rules: {
      required: dataType => {
        switch (dataType) {
          case "datetime":
            return v => !!v || "Vui lòng chọn ngày";
          case "number":
            return v => !isNaN(Number(v)) || "Vui lòng nhập số";
          case "boolean":
            return v => !!v || "Vui lòng chọn";
          case "choice":
            return v => v !== undefined || "Vui lòng chọn";
          case "multiple":
            return v => v.length > 0 || "Vui lòng chọn";
          case "belongs":
            return v => !isNaN(v) || "Vui lòng chọn";
          case "has-many":
            return v => v.length > 0 || "Vui lòng chọn";
          default:
            return v => !!v || "Vui lòng nhập vào ô trống";
        }
      },
      length: dataType => {
        switch (dataType) {
          case "datetime":
            return v => !!v || "Vui lòng chọn ngày";
          case "number":
            return v => !isNaN(Number(v)) || "Vui lòng nhập số";
          case "boolean":
            return v => !!v || "Vui lòng chọn";
          case "choice":
            return v => v !== undefined || "Vui lòng chọn";
          case "multiple":
            return v => v.length > 0 || "Vui lòng chọn";
          case "belongs":
            return v => !isNaN(v) || "Vui lòng chọn";
          case "has-many":
            return v => v.length > 0 || "Vui lòng chọn";
          default:
            return v => !!v || "Vui lòng nhập vào ô trống";
        }
      },
      email: () => {
        return v => !v || /.+@.+\..+/.test(v) || "E-mail không đúng định dạng";
      }
    }
  }),

  computed: {
    readableHeaders() {
      return this.headers.filter(h => !h.editOnly);
    },

    readableAndActionHeaders() {
      return this.readableHeaders.concat([
        {
          text: "",
          value: "actions",
          sortable: false
        }
      ]);
    },

    editableHeaders() {
      return this.headers.filter(h => !h.readOnly);
    },

    isCreateNew() {
      return this.editedIndex === -1;
    }
  },

  watch: {
    url(val) {
      if (!val) return this.clear();

      this.fetchData();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    },
    query() {
      this.onQueryChanged();
    }
  },

  methods: {
    onQueryChanged: debounce(function() {
      this.onFilterChanged();
    }, 500),

    onFilterChanged() {
      this.options = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10
      };
      this.fetchData();
    },

    async fetchData() {
      if (!this.url) return;

      try {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const params = {
          page,
          page_size: itemsPerPage
        };

        if (this.query) {
          params["search"] = this.query.trim();
        }

        if (sortBy && sortBy.length > 0) {
          params["order_by"] = sortBy[0];
          params["order_direction"] = sortDesc[0] ? "desc" : "asc";
        }

        const res = await request({
          url: this.url,
          params
        });

        this.items = res.data;
        this.totalItems = res.meta.total;
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.dialog = false;
      this.loading = false;
      this.dialogDelete = false;
      this.query = "";
      this.options = {};
      this.totalItems = 0;
      this.items = [];
      this.deleteLoading = false;
      this.editLoading = false;

      this.resetEditedItem();
    },

    resetEditedItem() {
      this.editedIndex = -1;
      const emptyData = {};

      for (const header of this.editableHeaders) {
        if (header.dataType === "belongs-to") {
          emptyData[header.value] = undefined;
        } else if (header.dataType === "has-many") {
          emptyData[header.value] = [];
        } else if (header.dataType === "choice") {
          emptyData[header.value] = header.required
            ? header.data[0]
            : undefined;
        } else if (header.dataType === "multiple-choice") {
          emptyData[header.value] = [];
        } else if (header.dataType === "datetime") {
          emptyData[header.value] = header.required
            ? new Date().toISOString()
            : undefined;
        } else if (header.dataType === "number") {
          emptyData[header.value] = 0;
        } else if (header.dataType === "boolean") {
          emptyData[header.value] = false;
        } else {
          emptyData[header.value] = "";
        }
      }

      this.editedItem = emptyData;
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        id: item.id
      };
      this.dialogDelete = true;
    },

    getUrl() {
      const urls = this.url.split("?");
      urls[0] = urls[0] + "/" + this.editedItem.id;
      return urls.join("?");
    },

    async deleteItemConfirm() {
      try {
        this.deleteLoading = false;

        await request({
          url: this.getUrl(),
          method: "DELETE"
        });

        this.items.splice(this.editedIndex, 1);
        this.closeDelete();

        this.$snackbar("Xóa dữ liệu thành công!", "success");
      } finally {
        this.deleteLoading = false;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetEditedItem();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.resetEditedItem();
      });
    },

    async save() {
      if (!this.$refs.form.validate()) return;

      if (this.editedIndex > -1) {
        await this.updateItem();
      } else {
        await this.createItem();
      }
      await this.fetchData();
    },

    async updateItem() {
      try {
        this.editLoading = true;
        await request({
          url: this.getUrl(),
          method: "PUT",
          data: this.getRequestData()
        });

        this.$snackbar("Cập nhật dữ liệu thành công!", "success");

        this.close();
      } finally {
        this.editLoading = false;
      }
    },

    async createItem() {
      try {
        this.editLoading = true;

        await request({
          url: this.url,
          method: "POST",
          data: this.getRequestData()
        });

        this.$snackbar("Tạo dữ liệu mới thành công!", "success");

        this.close();
      } finally {
        this.editLoading = false;
      }
    },

    getRequestData() {
      const data = {};

      for (const header of this.editableHeaders) {
        if (header.dataType === "belongs-to") {
          data[header.value] = this.editedItem[header.dataValue].id;
        } else if (header.dataType === "has-many") {
          data[header.value] = this.editedItem[header.dataValue].map(i => i.id);
        } else {
          if (this.editedItem[header.value] === undefined) {
            data[header.value] = null;
          } else {
            let value = this.editedItem[header.value];

            if (value instanceof String) {
              value = value.trim();
            }

            data[header.value] = value;
          }
        }
      }

      return data;
    }
  }
};
</script>
