<template>
  <v-menu
    v-model="menuVisible"
    offset-y
    min-width="290px"
    transition="scale-transition"
    :nudge-right="40"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
        :value="dateText"
        readonly
        clearable
        @click:clear="onClear"
      />
    </template>

    <div class="date-picker-container d-flex" style="background-color: white">
      <div class="flex-none d-flex flex-column">
        <v-date-picker v-model="date" landscape />

        <v-time-picker
          v-model="time"
          landscape
          style="border-radius-top-left: 0; border-radius-top-right: 0"
        />
      </div>

      <div class="pa-4 flex-none d-flex flex-column">
        <v-btn color="primary" class="mb-2 mx-0" @click="submit">Lưu lại</v-btn>
        <v-btn text @click="cancel">Hủy</v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    value: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      menuVisible: false,

      date: undefined,
      time: undefined,

      dateText: ""
    };
  },

  watch: {
    menuVisible(val) {
      if (val) {
        this.initPickerData();
      } else {
        this.clearPickerData();
      }
    },

    value() {
      this.initData();
    }
  },

  created() {
    this.initData();
  },

  methods: {
    initData() {
      this.dateText = !this.value
        ? undefined
        : dayjs(this.value).format("DD/MM/YYYY hh:mm A");
    },

    initPickerData() {
      const date = !this.value ? new Date() : new Date(this.value);

      const year = date.getFullYear();
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const day =
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
      const hour = date.getHours();
      const min = date.getMinutes();

      this.date = `${year}-${month}-${day}`;
      this.time = `${hour}:${min}`;
    },

    clearPickerData() {
      this.date = undefined;
      this.time = undefined;
    },

    onClear() {
      this.dateText = undefined;
      this.date = undefined;
      this.time = undefined;

      this.$emit("input", undefined);
    },

    cancel() {
      this.menuVisible = false;
    },

    submit() {
      const date = new Date(`${this.date} ${this.time}`);

      this.dateText = dayjs(date).format("DD/MM/YYYY hh:mm A");

      this.$emit("input", date.toISOString());
      this.menuVisible = false;
    }
  }
};
</script>

<style>
.date-picker-container .v-picker__title.v-picker__title--landscape {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
</style>
