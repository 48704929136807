<template>
  <v-autocomplete
    v-model="selectedResult"
    v-bind="$attrs"
    :items="items"
    :loading="loading"
    :search-input.sync="query"
    :hide-no-data="!query || query.length === 0 || loading || !items"
  >
    <template v-slot:append-item>
      <div
        v-if="loadMoreAvailable"
        :loading="loadingMore"
        class="py-2 text-center"
      >
        <v-btn depressed small color="primary" @click="fetchMore">
          Tải thêm
        </v-btn>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from "./debounce";
import request from "@/utils/request";

export default {
  props: {
    value: {
      type: [Object, Array, Number, String, Boolean],
      default: undefined,
    },

    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      query: undefined,
      loading: false,
      loadingMore: false,

      items: [],
      perPage: 10,
      currentPage: 1,
      totalItems: 0,
    };
  },

  computed: {
    selectedResult: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    loadMoreAvailable() {
      return this.currentPage * this.perPage < this.totalItems;
    },
  },

  watch: {
    query() {
      this.onQueryChanged();
    },
  },

  methods: {
    onQueryChanged: debounce(function () {
      this.fetchData();
    }, 500),

    async fetchData() {
      try {
        this.loading = true;

        const params = {
          page: this.currentPage,
          page_size: this.perPage,
        };

        if (this.query) {
          params["search"] = this.query;
        }

        const res = await request({
          url: this.url,
          params,
        });

        this.items = res.data;
        this.totalItems = res.meta.total;
      } finally {
        this.loading = false;
      }
    },

    async fetchMore() {
      if (!this.loadMoreAvailable) return;

      try {
        this.loadingMore = true;

        const params = {
          page: this.currentPage + 1,
          page_size: this.perPage,
        };

        if (this.query) {
          params["search"] = this.query;
        }

        const res = await request({
          url: this.url,
          params,
        });

        this.currentPage += 1;
        this.items = this.items.concat(res.data);
        this.totalItems = res.meta.total;
      } finally {
        this.loadingMore = false;
      }
    },
  },
};
</script>
